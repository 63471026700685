<template>
  <div class="container">
    <div class="row">
      <BreadCrumb
        main="Survey"
        sub="Responses"
      >
        <button type="button" @click="goBack('/dashboard/surveys')" class="btn btn-primary">Go Back</button>
      </BreadCrumb>
      <div v-if="surveyResponses?.length === 0" class="no-answer">
        No Responses for this Survey
      </div>
      <div class="row ps-0 mt-4">
        <div class="col-5">
          <div class="input-group mb-3">
            <label class="input-group-text" for="inputGroupSelect01"
              >Filter By</label
            >
            <select
              class="form-select"
              id="inputGroupSelect01"
              @change.prevent="filterHandler"
            >
              <option selected value="">Choose...</option>
              <option value="name">Created By</option>
              <option value="date">Date Created</option>
            </select>

            <input
              v-if="filter !== ''"
              :type="filter === 'date' ? 'date' : 'text'"
              class="form-control"
              aria-label="Text input with dropdown button"
              @input.prevent="filterValueHandler"
              placeholder="enter name"
            />
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body overflow-auto">
          <table :class="['table table-striped', isLoading ? 'less-visible-table' : '']">
            <thead>
              <tr>
                <th>Created By</th>
                <th>City</th>
                <th>Region</th>
                <th>Date Created</th>
                <th class="text-center">Answers</th>
                <th class="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!surveyResponses?.responses.length">
                <td colspan="6" class="text-center">No data available</td>
              </tr>
              <tr
                v-else
                v-for="(response, index) in surveyResponses?.responses"
                :key="index"
              >
                <td>
                  {{ response?.user.firstname }} {{ response?.user.lastname }}
                </td>
                <td>{{ response?.city }}</td>
                <td>{{ response?.region }}</td>
                <td>{{ formatDateTime(response?.createdAt) }}</td>
                <td class="text-center">{{ response?.Answer?.length }}</td>
                <td class="text-end">
                  <router-link
                    :to="`/answer/${response?.id}`"
                    class="btn btn-sm btn-secondary"
                    >View Answers</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <span class="align-self-center"
          >{{ displayedResponses }} out of {{ surveyResponses?.total }}</span
        >
        <PaginationComponent
          v-if="surveyResponses?.total > 0"
          :page="page"
          :total="surveyResponses?.total"
          :pageSize="pageSize"
          @changePage="changePage"
          class="align-self-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateFormatMixin from "@/mixins/dateFormatMixin";
import paginationMixin from "@/mixins/paginationMixin";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";

export default {
  components: {
    BreadCrumb,
    PaginationComponent,
  },
  mixins: [
    dateFormatMixin,
    paginationMixin
  ],
  data() {
    return {
      page: 1,
      pageSize: 10,
      surveyResponses: null,
      filter: "",
      filterValue: "",
      total: 0,
    };
  },
  watch: {
    page: {
      async handler(val) {
        if (val) {
          await this.fetchResponses()
        }
      },
      immediate: true,
    },
    filter: {
      async handler(newValue, oldValue) {
        if (oldValue !== "" && newValue === "") {
          this.page = 1
        }
      },
      immediate: true,
    },
    filterValue: {
      async handler() {
        this.fetchResponses()
      }
    }
  },
  computed: {
    ...mapGetters(["isLoading", "getSingleAnsweredSurvey"]),
    questionAnswes() {
      return this.getSingleAnsweredSurvey;
    },
  },
  async mounted() {
    const surveyId = this.$route.params.id;
    this.$store.commit("SET_LOADING", true);
    if (surveyId) {
      await this.fetchResponses()
    }
  },
  methods: {
    ...mapActions(["fetchSingleAnsweredSurvey"]),
    filterHandler(event) {
      this.filter = event.target.value;
    },
    filterValueHandler(event) {
      this.filterValue = event.target.value;
    },
    async fetchResponses() {
      const surveyId = this.$route.params.id;
      let query = `?page=${this.page}&pageSize=${this.pageSize}`;
      if (this.filter !== "" && this.filterValue !== "") {
        query += `&${this.filter}=${this.filterValue}`
      }

      await this.fetchSingleAnsweredSurvey({ id: surveyId, query });
      this.surveyResponses = this.$store.state.survey.singleAnsweredSurvey
      this.total = this.surveyResponses?.total
    },
    goBack(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}

.no-answer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}
.less-visible-table {
  opacity: 0.5;
}
@media (max-width: 576px) {
  table {
    width: max-content;
  }
  .col-5 {
    width: 100%;
  }
}
</style>
