<template>
  <div class="container-sm">
    <BreadCrumb main="Survey" sub="List">
      <button type="button" @click="goBack(`/dashboard/dashmain`)" class="btn btn-primary">Go Back</button>
    </BreadCrumb>
    <div v-if="isLoading" class="loader-container">
      <LoaderComponent />
    </div>
    <div v-else class="row">
      <div class="card">
        <div class="card-body overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 40%">Survey Name</th>
                <th>Date Created</th>
                <th class="text-center">Questions</th>
                <th class="text-center">Responses</th>
                <th class="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(survey, surveyIdx) in surveys" :key="surveyIdx">
                <td>{{ survey?.title }}</td>
                <td>{{ formatDateTime(survey?.createdAt) }}</td>
                <td class="text-center">{{ survey?.questionCount }}</td>
                <td class="text-center">{{ survey?.surveyResponseCount }}</td>
                <td class="text-end">
                  <router-link
                    :to="`/single-survey/${survey.id}`"
                    class="btn btn-sm btn-secondary"
                    >View Responses</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <span class="align-self-center"
          >{{ displayedResponses }} out of {{ total }}</span
        >
        <PaginationComponent
          v-if="total > 0"
          :page="page"
          :total="total"
          :pageSize="pageSize"
          @changePage="changePage"
          class="align-self-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateFormatMixin from "@/mixins/dateFormatMixin";
import paginationMixin from "@/mixins/paginationMixin";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";

export default {
  name: "SurveyTableView",
  mixins: [
    dateFormatMixin,
    paginationMixin
  ],
  components: {
    LoaderComponent,
    BreadCrumb,
    PaginationComponent
  },
  data() {
    return {
      surveys: [],
      currentPage: 1,
      surveysPerPage: 5,
      page: 1,
      pageSize: 10,
      total: 0
    };
  },
  watch: {
    page: {
      async handler(val) {
        if (val) {
          await this.fetchSurveys();
        }
      },
      immediate: true
    },
  },
  async mounted() {
    this.$store.commit("SET_LOADING", true);
    await this.fetchSurveys();
  },
  computed: {
    ...mapGetters(["isLoading"]),
    totalSurveys() {
      return this.surveys.length;
    },
    paginatedSurveys() {
      const start = (this.currentPage - 1) * this.surveysPerPage;
      const end = start + this.surveysPerPage;
      return this.surveys.slice(start, end);
    },
  },
  methods: {
    ...mapActions(["fetchAnsweredSurveys"]),
    async fetchSurveys () {
      await this.fetchAnsweredSurveys({ page: this.page, pageSize: this.pageSize });
      const responses = this.$store.state.survey.answeredSurveys;
      this.surveys = JSON.parse(responses?.surveys);
      this.page = responses?.page;
      this.pageSize = responses?.pageSize;
      this.total = responses?.total;
    },
    goBack(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}
table {
  width: max-content;
}
</style>
