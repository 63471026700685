<template>
  <div>
    <div class="text-center d-flex justify-content-between">
      <b-spinner variant="primary" type="grow"></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style>
</style>