<template>
  <div class="container">
    <div v-if="isLoading" class="loader-container">
      <LoaderComponent />
    </div>
    <div v-else>
      <BreadCrumb
        main="Survey"
        sub="Answers"
      >
        <button type="button" @click="goBack(`/single-survey/${currentResponse?.surveyId}`)" class="btn btn-primary">Go Back</button>
      </BreadCrumb>

      <div
        class="row"
        v-for="(answer, idx) in currentResponse?.Answer"
        :key="idx"
      >
        <div class="card">
          <div class="card-body">
            <label class="form-label">{{ answer?.question.text }}</label>
            <div
              v-if="
                ['email', 'number', 'url', 'text'].includes(
                  answer?.question.type
                )
              "
            >
              <input
                class="form-control"
                :value="answer?.answerText"
                type="text"
                disabled
              />
            </div>

            <div v-if="answer?.question.type === 'checkbox'">
              <div
                v-for="(option, index) in answer?.question.options"
                :key="index"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :checked="answer?.options.includes(option)"
                    disabled
                  />
                  <label class="form-check-label" :for="option">{{
                    option
                  }}</label>
                </div>
              </div>
            </div>

            <div v-if="answer?.question.type === 'radio'">
              <div
                v-for="(option, index) in answer?.question.options"
                :key="index"
              >
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    :checked="answer?.answerText.includes(option)"
                    disabled
                  />
                  <label class="form-check-label" :for="option">{{
                    option
                  }}</label>
                </div>
              </div>
            </div>

            <div v-if="answer?.question.type === 'textarea'">
              <textarea
                class="form-control"
                :value="answer?.answerText"
                disabled
              ></textarea>
            </div>

            <div v-if="answer?.question.type === 'table'">
              <table class="table table-bordered">
                <thead>
                  <tr v-if="answer?.question.columns">
                    <th
                      v-for="(column, index) in answer?.question.columns"
                      :key="index"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, rowIdx) in answer?.question.rows"
                    :key="rowIdx"
                  >
                    <td>{{ row }}</td>
                    <td
                      v-for="(column, colIdx) in answer?.question.columns.slice(
                        1
                      )"
                      :key="colIdx"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :value="formatTableValue(answer, colIdx, rowIdx)"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BreadCrumb,
    LoaderComponent,
  },
  data() {
    return {
      currentResponse: null,
    };
  },
  async mounted() {
    await this.fetchCurrentAnswerResponse(this.$route.params.id);
    this.currentResponse = this.$store.state.survey.currentAnswerResponse;
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  methods: {
    ...mapActions(["fetchCurrentAnswerResponse"]),
    formatTableValue(answer, colIdx, rowIdx) {
      const ans = answer?.options.find(
        (option) => option.col === colIdx && option.row === rowIdx
      );
      return ans?.value;
    },
    goBack(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}
</style>
