<template>
  <div class="wrapper">
    <div
      class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0"
    >
      <div class="container">
        <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
          <div class="col mx-auto">
            <div class="card mb-0">
              <div class="card-body">
                <div class="p-4">
                  <div class="text-center mb-4">
                    <h5 class="">Form256</h5>
                    <p class="mb-0">Please log in to your account</p>
                  </div>
                  <div class="form-body">
                    <form class="row g-3">
                      <span class="text-danger" v-show="errorMessage">{{
                        errorMessage
                      }}</span>
                      <div class="col-12">
                        <label class="form-label">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          v-model="email"
                          placeholder="jhon@example.com"
                        />
                        <span v-show="errors['email']" class="text-danger">{{
                          errors["email"]
                        }}</span>
                      </div>
                      <div class="col-12">
                        <label class="form-label">Password</label>
                        <div class="input-group">
                          <input
                            type="password"
                            class="form-control border-end-0"
                            name="password"
                            v-model="password"
                            placeholder="Enter Password"
                          />
                        </div>
                        <span v-show="errors['password']" class="text-danger">{{
                          errors["password"]
                        }}</span>
                      </div>
                      <div class="col-md-6">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                            >Remember Me</label
                          >
                        </div>
                      </div>
                      <!-- <div class="col-md-6 text-end"> <a
                                                    href="authentication-forgot-password.html">Forgot Password ?</a>
                                            </div> -->
                      <div class="col-12">
                        <div class="d-grid">
                          <button
                            @click="loginHandler"
                            type="submit"
                            class="btn btn-primary"
                          >
                            <div v-if="isLogging"><SpinnerComponent /></div>
                            <span v-else> Sign in </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as yup from "yup";
import SpinnerComponent from "@/components/common/SpinnerComponent.vue";

export default {
  name: "LoginView",
  components: {
    SpinnerComponent,
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {},
    };
  },
  computed: {
    ...mapGetters(["isLogging"]),
    schema() {
      return yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
      });
    },
    errorMessage() {
      return this.$store.state.login.authError;
    },
  },
  methods: {
    ...mapActions(["login"]),
    async loginHandler($event) {
      $event.preventDefault();

      try {
        await this.schema.validate(
          { email: this.email, password: this.password },
          { abortEarly: false }
        );
        this.errors = {};

        this.login({ email: this.email, password: this.password }).then(() => {
          const user = JSON.parse(localStorage.getItem("user"));
          if (user?.role === "agent") {
            this.$router.push({ name: "survey-table" });
          } else {
            this.$router.push({ name: "dashboard-table" });
          }
          
        });
      } catch (err) {
        if (err.inner) {
          this.errors = err.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {});
        }
      }
    },
  },
};
</script>
