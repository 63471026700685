<template>
    <div>
        <div>
            <NavBar />
        </div>
        <div class="page-wrapper">
            <div class="page-content">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/common/NavBar.vue'

export default {
  name: 'LayoutView',
  components: {
    NavBar
  },
}
</script>
