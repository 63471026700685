export default {
    methods: {
        formatDateTime(string) {
            let date = new Date(string);
            date.setHours(date.getHours() + 3);

            return date.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
              timeZone: "UTC"
            });
        },
        formatDate(string) {
            let date = new Date(string);
            date.setHours(date.getHours() + 3);

            return date.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "UTC"
            });
        }
    }
}
