<template>
    <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
      <div v-show="main" class="breadcrumb-title pe-3">{{ main }}</div>
      <div class="ps-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><router-link :to="{ path: '/dashboard/dashmain' }"><i class="lni lni-home"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ sub }}</li>
          </ol>
        </nav>
      </div>
      <div class="ms-auto">
        <slot></slot>
      </div>
    </div>
</template>
<script>
export default {
  name: "BreadCrumb",
  props: {
    main: {
      type: String,
      required: false,
    },
    sub: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.page-breadcrumb {
  display: flex;
}
</style>
