<template>
    <nav aria-label="Page navigation">
        <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: page === 1 }">
                <a class="page-link" @click.prevent="changePage(page - 1)">Previous</a>
            </li>
            <li class="page-item" v-for="(pge, idx) in displayedPages" :key="idx">
                <a
                    :class="[page === pge ? 'active' : '', 'page-link']" 
                    @click.prevent="changePage(pge)"
                >
                    {{ pge }}
                </a>
            </li>
            <li class="page-item" :class="{ disabled: page === totalPages }">
                <a class="page-link" @click.prevent="changePage(page + 1)">Next</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'PaginationComponent',
    props: {
        page: {
            required: true
        },
        total: {
            required: true
        },
        pageSize: {
            default: 10
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.pageSize);
        },
        displayedPages() {
            const pages = [];
            const totalPages = this.totalPages;
            const currentPage = this.page;

            if (totalPages <= 10) {
                for (let i = 1; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                if (currentPage <= 4) {
                    for (let i = 1; i <= 5; i++) {
                        pages.push(i);
                    }
                    pages.push('...');
                    pages.push(totalPages);
                } else if (currentPage > totalPages - 4) {
                    pages.push(1);
                    pages.push('...');
                    for (let i = totalPages - 4; i <= totalPages; i++) {
                        pages.push(i);
                    }
                } else {
                    pages.push(1);
                    pages.push('...');
                    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                        pages.push(i);
                    }
                    pages.push('...');
                    pages.push(totalPages);
                }
            }
            return pages;
        }
    },
    methods: {
        changePage(page) {
            if (page === '...') return;
            this.$emit('changePage', page);
        }
    }
}
</script>

<style>
.page-link {
    cursor: pointer;
}
.page-item.disabled .page-link {
    cursor: not-allowed;
}
</style>
