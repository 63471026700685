<template>
  <div id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import 'leaflet/dist/leaflet.css';

export default {
  name: 'App',
  data() {
    return {
      idleLimit: 60 * 60 * 1000,
      idleTimer: null,
      location: null
    }
  },
  watch: {
    location: {
      handler: function (old, val) {
        if (val) {
          this.$store.commit('SET_SURVEY_LOCATION', val)
        }
      },
      deep: true,
      immediate: true,  
    }
  },
  methods: {
    ...mapActions(['logout']),

    resetIdleTimer() {
      clearTimeout(this.idleTimer),
        this.idleTimer = setTimeout(this.logoutUser, this.idleLimit);
    },
    logoutUser() {
      this.logout()
      this.$router.push('/login')
    },
    handleUserActivity() {
      this.resetIdleTimer()
    },
    showPosition(position) {
      this.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          (error) => {
            console.log(error);
          }
        )
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
  },
  async created() {
    await this.getLocation()
  },
  async mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.showPosition, (error) => {
        console.log(error);
      })
    }
    this.resetIdleTimer()

    window.addEventListener("mousemove", this.handleUserActivity)
    window.addEventListener("keydown", this.handleUserActivity)
    window.addEventListener("scroll", this.handleUserActivity)
    window.addEventListener("click", this.handleUserActivity)
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleUserActivity)
    window.removeEventListener("keydown", this.handleUserActivity)
    window.removeEventListener("scroll", this.handleUserActivity)
    window.removeEventListener("click", this.handleUserActivity)

    clearTimeout(this.idleTimer)
  }
}
</script>
