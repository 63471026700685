import instance from "../../config";

const state = {
  loadingUser: false,
  users: [],
  allUsers: [],
};

const mutations = {

  SET_LOADING(state, isLoading) {
    state.loadingUser = isLoading;
  },
 
  SET_USERS(state, users) {
    state.users = users;
  },

  SET_ALL_USERS(state, allUsers) {
    state.allUsers = allUsers
  }
};

const getters = {
  getUsers: (state) => state.users,
  isLoadingUsers: (state) => state.loadingUser,
  getAllUsers: (state) => state.allUsers
};

const actions = {
  async fetchUsers({ commit }, payload) {
    commit("SET_LOADING", true);
    await instance
      .get(`/users?page=${payload.page}&pageSize=${payload.pageSize}`)
      .then((response) => {
        commit("SET_USERS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  // eslint-disable-next-line
  async deleteUser({ commit }, id) {
    return await instance
      .delete(`/users/${id}`)
  },
  // eslint-disable-next-line
  async updateUser({ commit }, payload) {
    return await instance
      .put(`/users/${payload.id}`, payload)
  },
  // eslint-disable-next-line
  async createUser({ commit }, payload) {
    return await instance
      .post(`/register`, payload)
  },
  async fetchAllUsers({ commit }) {
    await instance
      .get('/users/all')
      .then((response) => {
        commit("SET_ALL_USERS", response.data.data)
      })
      .catch((error) => {
        console.error(error)
      });
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
};
