<template>
    <ModalComponent title="Delete User" @close="closeHandler">
        <template v-slot:body>
            <p class="mb-0">
                Are you sure you want to delete
                <span class="text-primary">{{ selectedUser?.firstname }} {{ selectedUser?.lastname }}</span>?
            </p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-sm btn-secondary"
                @click.prevent="closeHandler">Cancel</button>
            <button type="button" class="btn btn-sm btn-danger"
                @click.prevent="deleteSelectedUser(selectedUser)">Delete</button>
        </template>
    </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/common/ModalComponent.vue";

export default {
    name: "DeleteUserModal",
    components: {
        ModalComponent,
    },
    props: {
        selectedUser: {
            required: true,
        },
    },
    methods: {
        deleteSelectedUser(user) {
            this.$emit("delete", user);
        },
        closeHandler() {
            this.$emit("close");
        },
    },
};
</script>
