<template>
  <ModalComponent :title="`${isEdit ? 'Edit' : 'Create'} User`" @close="closeHandler">
    <template v-slot:body>
      <div class="mb-3">
        <label for="firstname" class="form-label">First Name</label>
        <input type="text" class="form-control" id="firstname" v-model="user.firstname" />
        <span class="text-danger" v-show="errorMessage['firstname']">{{ errorMessage['firstname'] }}</span>
      </div>

      <div class="mb-3">
        <label for="lastname" class="form-label">Last Name</label>
        <input type="text" class="form-control" id="lastname" v-model="user.lastname" />
        <span class="text-danger" v-show="errorMessage['lastname']">{{ errorMessage['lastname'] }}</span>
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" :disabled="isEdit" v-model="user.email" />
        <span class="text-danger" v-show="errorMessage['email']">{{ errorMessage['email'] }}</span>
      </div>

      <div class="mb-3">
        <label for="role" class="form-label">Role</label>
        <select class="form-select" id="role" v-model="user.role">
          <option value="agent">Agent</option>
          <option value="admin">Admin</option>
        </select>
        <span class="text-danger" v-show="errorMessage['role']">{{ errorMessage['role'] }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-sm btn-secondary" @click.prevent="closeHandler">Cancel</button>
      <button type="button" :class="['btn btn-sm', isEdit ? 'btn-success' : 'btn-primary']"
        @click.prevent="handleSaveChanges">{{ isEdit ? 'Update' : 'Save'}}</button>
    </template>
  </ModalComponent>
</template>

<script>
import * as yup from "yup";
import { mapActions } from "vuex";
import ModalComponent from "@/components/common/ModalComponent.vue";

export default {
  name: "EditCreateUserModal",
  components: {
    ModalComponent,
  },
  props: {
    selectedUser: {
      type: [Object],
    },
    title: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        email: "",
        role: "",
      },
      errorMessage: {}
    }
  },
  computed: {
    schema() {
      return yup.object().shape({
        email: yup.string().email().required(),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        role: yup.string().oneOf(['agent', 'admin']).required(),
      });
    }
  },
  mounted() {
    if (this.selectedUser?.id) {
      this.user = { ...this.selectedUser }
    }
  },
  methods: {
    ...mapActions(["updateUser", "createUser"]),
    closeHandler() {
      this.$emit("closeHandler");
    },
    async handleSaveChanges() {
      try {
        await this.schema.validate({
          email: this.user.email,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          role: this.user.role,
        }, { abortEarly: false });

        this.errorMessage = {}

        if (this.isEdit) {
          await this.updateUser(this.user);
          this.$emit("closeHandler");
          this.$emit("refresh");
        } else {
          await this.createUser(this.user);
          this.$emit("closeHandler");
          this.$emit("refresh");
        }
      } catch (err) {
        if (err.inner) {
          this.errorMessage = err.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {});
        }
      }
    }
  }
}
</script>