<template>
    <fragment>
        <div v-if="isLoading" class="loader-container">
              <LoaderComponent />
            </div>
        <div v-else>
            <div class="container-sm">
            <h5 class="text-uppercase">{{ survey?.title }}</h5>
            <p>{{ survey?.introduction }}</p>
            <hr />
        </div>

        <SurveySection
            v-for="(section, sectionIdx) in survey?.sections"
            v-show="page === sectionIdx"
            :key="sectionIdx"
            :section="section"
            :sectionIdx="sectionIdx"
            :errors="errors"
            :survey="survey"
            :currentSelectedSurvey="currentSelectedSurvey"
            :isLoading="isLoading"
            :isSubmiting="isSubmiting"
            @updateAnswer="updateAnswer"
            @validateField="validateField"
            @updateCheckboxAnswer="updateCheckboxAnswer"
            @prevButtonHandler="prevButtonHandler"
            @nextButtonHandler="nextButtonHandler"
            @updateTableAnswer="updateTableAnswer"
            @submitHandler="submitHandler"
        />
        </div>

        <ModalComponent
            v-show="showModal"
            title="Alert"
        >
            <template v-slot:body>
                <p>{{ message }}</p>
            </template>
            <template v-slot:footer>
                <button type="button" @click="closeHandler" class="btn btn-sm btn-secondary">Okay</button>
            </template>
        </ModalComponent>
    </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/common/ModalComponent";
import SurveySection from "@/components/surveys/SurveySection.vue";
import LoaderComponent from '@/components/common/LoaderComponent.vue'

export default {
    name: "SurveyFormView",
    components: {
        ModalComponent,
        SurveySection,
        LoaderComponent
    },
    data() {
        return {
            survey: null,
            page: 0,
            validationState: {},
            answers: [],
            errors: [],
            showModal: false,
            message: "",
            currentSelectedSurvey: null,
        };
    },
    watch: {
        answers: {
            handler: function(val) {
                if (!val.length) {
                    return
                }

                localStorage.setItem(this.surveyId, JSON.stringify({
                    surveyId: this.$route.params.id,
                    answers: val
                }));
            },
            deep: true,
            immediate: true,
        },
    },
    async mounted() {
        const surveyId = this.$route.params.id;
        this.$store.commit("SET_LOADING", true);
        await this.fetchCurrentSurvey(surveyId);
        this.survey = this.$store.state.survey.currentSurvey;
    },
    computed: {
        ...mapGetters(['isLoading','isSubmiting']),
        user() {
            return this.$store.state.login.user;
        },
        surveyId() {
            return this.$route.params.id;
        },
    },
    created() {
        this.currentSelectedSurvey = JSON.parse(localStorage.getItem(this.surveyId)) || null;
        this.answers = this.currentSelectedSurvey?.answers || [];
    },
    methods: {
        ...mapActions(["fetchCurrentSurvey", "submitAnswers"]),
        closeHandler() {
            this.showModal = false;
            this.$router.push("/");
        },
        async submitHandler() {
            this.$store.commit("SET_SUBMIT", true);
            const isValid = await this.validateSection();
            if (isValid) {
                this.$store.commit("SET_SUBMIT", false);
                return;
            }
            const location = this.$store.state.survey.surveyLocation;
            const response = {
                surveyId: this.survey.id,
                userId: this.$store.state.login.user.id,
                longitude: location['longitude'],
                latitude: location['latitude'],
                answers: this.answers
            }
            await this.submitAnswers(response).then((res) => {
                if (res?.status === 201) {
                    this.$store.commit("SET_SUBMIT", false);
                    this.message = "Thank you for participating in the survey. Your responses have been submitted successfully."
                    this.showModal = true;
                    localStorage.removeItem(this.surveyId);
                }
            })
            .catch((err) => {
                if (err) {
                    this.$store.commit("SET_SUBMIT", false);
                    this.message = "An error occurred while submitting your responses. Please try again later."
                    this.showModal = true;
                }
            });
        },
        async nextButtonHandler() {
            if (!this.$store.state.survey.surveyLocation) {
                alert("Please enable location services to proceed.");
                return
            }
            const isValid = await this.validateSection();
            if (!isValid) {
                this.page++;
            }
        },
        prevButtonHandler() {
            this.errors = [];
            this.page--;
        },
        validateField(fieldId, validate) {
            this.validationState[fieldId] = validate;
        },
        async processTable(answer, question) {
            const tablePromises = [];
            for (let rowIndex = 0; rowIndex < question.rows.length; rowIndex++) {
                for (let colIndex = 0; colIndex < (question.columns.slice(1)).length; colIndex++) {
                    const fieldId = `${question.id}-${rowIndex}-${colIndex}`;

                    if (this.validationState[fieldId]) {
                        tablePromises.push(await this.validationState[fieldId]());
                    } else {
                        const foundAnswer = answer?.options.find(ans => ans.row === rowIndex && ans.col === colIndex)
                        if (foundAnswer) {
                            tablePromises.push({ "valid": true, "errors": [], "failedRules": {}, "regenerateMap": {} });
                        } else {
                            tablePromises.push(Promise.resolve(true));
                        }
                    } 
                }
            }
            return await Promise.all(tablePromises);
        },
        async validateSection() {
           const currentSection = this.survey.sections[this.page].questions;
            let promises = currentSection.map(async (question) => {
                const answer = this.currentSelectedSurvey?.answers?.find((ans) => ans.questionId === question.id);
                if (question.type === "table") {
                    return { "valid": true, "errors": [], "failedRules": {}, "regenerateMap": {} }
                } else {
                    if (answer || this.validationState[question.id]) {
                        return answer
                            ? { "valid": true, "errors": [], "failedRules": {}, "regenerateMap": {} }
                            : await this.validationState[question.id]();
                    }
                    return Promise.resolve(true);
                }
            });
            const results = await Promise.all(promises);
            this.errors = results;
            return !results.every((result) => this.processPromises(result));
        },
        processPromises (item) {
            if (Array.isArray(item)) {
                return item.every((i) => typeof i === "object")
            }
            return typeof item === "object";
        },
        updateAnswer(questionId, value) {
            const answerIndex = this.answers.findIndex(
                (answer) => answer.questionId === questionId
            );
            if (answerIndex > -1) {
                this.answers[answerIndex].answerText = value;
            } else {
                this.answers.push({
                    questionId,
                    answerText: value
                });
            }
        },
        updateCheckboxAnswer(questionId, option, isChecked) {
            const answerIndex = this.answers.findIndex(
                (answer) => answer.questionId === questionId
            );

            if (isChecked === undefined) {
                const options = this.answers[answerIndex].options.map((opt) => {
                    if(option?.includes(opt)) {
                        return option
                    }
                    return opt
                })
                this.answers[answerIndex].options = options;
                return
            }

            if (answerIndex > -1) {
                const existingAnswer = this.answers[answerIndex].options;
                if (isChecked) {
                    existingAnswer.splice(existingAnswer.indexOf(option), 1);
                } else {
                    existingAnswer.push(option);
                }
            } else {
                this.answers.push({
                    questionId,
                    options: [option]
                });
            }
        },
        updateTableAnswer(questionId, option) {
            const answerIndex = this.answers.findIndex(
                (answer) => answer.questionId === questionId
            );
            if (answerIndex > -1) {
                const existingAnswer = this.answers[answerIndex].options;
                const optionIndex = existingAnswer.findIndex((ans) => ans.row === option.row && ans.col === option.col);

                if (optionIndex === -1) {
                    this.answers[answerIndex].options.push(option)
                } else {
                    this.answers[answerIndex].options[optionIndex] = option;
                    this.answers = [...this.answers];
                }
            } else {
                this.answers.push({
                    questionId,
                    options: [option]
                });
            }
        }
    }
};
</script>


<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

</style>
