<template>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" aria-modal="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button @click="closeHandler" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalComponent',
        props: {
            title: {
                type: String,
            },
        },
        methods: {
            closeHandler() {
                this.$emit('close')
            }
        }
    }
</script>
<style scoped>
    .modal {
        display: block;
        background: rgba(0, 0, 0, 0.5);
    }
    button.close {
        border: none;
        background-color: unset;
        font-size: 1.5rem;
    }
</style>
