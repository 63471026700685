<template>
    <ModalComponent title="View Asset" @close="closeHandler">
        <template v-slot:body>
           <table class="table table-striped">
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{selectedAsset?.name}}</td>
                </tr>
                <tr>
                    <td>Category</td>
                    <td>{{selectedAsset?.category}}</td>
                </tr>
                <tr>
                    <td>Location</td>
                    <td>{{selectedAsset?.location}}</td>
                </tr>
                <tr>
                    <td>Purchase Date</td>
                    <td>{{formatDate(selectedAsset?.purchaseDate)}}</td>
                </tr>
                <tr>
                    <td>Purchase Price</td>
                    <td>{{selectedAsset?.purchasePrice}}</td>
                </tr>
                <tr>
                    <td>Current Asset Value</td>
                    <td>{{selectedAsset?.currentAssetValue}}</td>
                </tr>
                <tr>
                    <td>Depreciation Rate</td>
                    <td>{{selectedAsset?.depreciationRate}}</td>
                </tr>
                <tr>
                    <td>Condition</td>
                    <td>{{selectedAsset?.condition}}</td>
                </tr>
                <tr>
                    <td>Serial Number</td>
                    <td>{{selectedAsset?.serialNumber}}</td>
                </tr>
                <tr>
                    <td>Manufacturer</td>
                    <td>{{selectedAsset?.manufacturer}}</td>
                </tr>
                <tr>
                    <td>Expiry Date</td>
                    <td>{{formatDate(selectedAsset?.expiryDate)}}</td>
                </tr>
                <tr>
                    <td>Maintenance Shedule</td>
                    <td>{{selectedAsset?.maintenanceShedule}}</td>
                </tr>
                <tr>
                    <td>Last Maintenance Date</td>
                    <td>{{formatDate(selectedAsset?.lastMaintenanceDate)}}</td>
                </tr>
                <tr>
                    <td>Assigned To</td>
                    <td>{{ selectedAsset?.user.firstname }} {{ selectedAsset?.user.lastname }}</td>
                </tr>
                <tr>
                    <td>Usage Status</td>
                    <td>{{selectedAsset?.usageStatus}}</td>
                </tr>
                <tr>
                    <td>Utilisation Rate</td>
                    <td>{{selectedAsset?.utilisationRate}}</td>
                </tr>
                <tr>
                    <td>Created At</td>
                    <td>{{formatDate(selectedAsset?.createdAt)}}</td>
                </tr>
                <tr>
                    <td>Updated At</td>
                    <td>{{formatDate(selectedAsset?.updatedAt)}}</td>
                </tr>
            </tbody>
           </table>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-sm btn-secondary"
                @click.prevent="closeHandler">Cancel</button>
        </template>
    </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/common/ModalComponent.vue";
import dateFormatMixin from "@/mixins/dateFormatMixin";

export default {
    name: "ViewAssetModal",
    mixins: [dateFormatMixin],
    components: {
        ModalComponent,
    },
    props: {
        selectedAsset: {
            required: true,
        },
    },
    methods: {
        closeHandler() {
            this.$emit("closeHandler");
        },
    },
};
</script>
