<template>
  <div class="container-sm">
    <BreadCrumb main="Assets" sub="List">
      <button
        type="button"
        @click="
          showEditModal = true;
        "
        class="btn-md btn btn-primary"
      >
        <i class="lni lni-circle-plus"></i> Create Assets
      </button>
    </BreadCrumb>

    <div v-if="isLoadingUsers" class="loader-container">
      <LoaderComponent />
    </div>
    <div v-else class="row">
      <div class="card">
        <div class="card-body overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr class="asset-tables">
                <th>Asset Name</th>
                <th>Category</th>
                <th>Location</th>
                <th>Serial Number</th>
                <th>Assigned To</th>
                <th class="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="assets.length < 1">
                <td class="text-center" colspan="6">No Data Available</td>
              </tr>
              <tr v-else v-for="(asset, assetIdx) in assets" :key="assetIdx">
                <td>{{ asset?.name }}</td>
                <td>{{ asset?.category }}</td>
                <td>{{ asset?.location }}</td>
                <td>{{ asset?.serialNumber }}</td>
                <td>{{ asset?.user?.firstname }} {{ asset?.user?.lastname }}</td>
                <td class="text-end edit">
                  <i
                    class="lni lni-eye fs-5 me-3 text-success"
                    @click="viewAsset(asset)"
                  ></i>
                  <i
                    class="lni lni-pencil fs-5 me-3 text-primary"
                    @click="editAsset(asset)"
                  ></i>
                  <i
                    class="lni lni-trash fs-5 text-danger"
                    @click="confirmDeleteAsset(asset, assetIdx)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <CreateAssetModal
        v-if="showEditModal"
        @closeHandler="
          showEditModal = false;
          selectedAsset = null
        "
        :selectedAsset="selectedAsset"
        :isEdit="isEdit"
        @refreshHandler="isRefreshed = true"
      />

      <DeleteAssetModal
        v-show="showDeleteAssetModal"
        :selectedAsset="selectedAsset"
        @closeHandler="showDeleteAssetModal = false; selectedAsset = null;"
        @delete="deleteSelectedAsset(selectedAsset); selectedAsset = null;"
      />

      <ViewAssetModal
        v-show="showViewModal"
        :selectedAsset="selectedAsset"
        @closeHandler="showViewModal = false; selectedAsset = null;"
      />
      <div class="d-flex justify-content-between">
        <span class="align-self-center"
          >{{ displayedResponses }} out of {{ total }}</span
        >
        <PaginationComponent
          v-if="total > 0"
          :page="page"
          :total="total"
          :pageSize="pageSize"
          @changePage="changePage"
          class="align-self-center"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import paginationMixin from "@/mixins/paginationMixin";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import CreateAssetModal from "./CreateAssetModal.vue";
import DeleteAssetModal from "./DeleteAssetModal.vue";
import ViewAssetModal from "./ViewAssetModal.vue";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";

export default {
  name: "UsersTableView",
  mixins: [paginationMixin],
  components: {
    BreadCrumb,
    CreateAssetModal,
    LoaderComponent,
    PaginationComponent,
    DeleteAssetModal,
    ViewAssetModal
  },
  data() {
    return {
      users: [],
      assets: [],
      page: 1,
      pageSize: 12,
      total: 0,
      showEditModal: false,
      showCreateUser: false,
      selectedAsset: null,
      showDeleteAssetModal: false,
      deleteIndex: null,
      isEdit: false,
      isRefreshed: false,
      showViewModal: false,
    };
  },
  watch: {
    page: {
      async handler(val) {
        if (val) {
          await this.fetchAssetsList();
        }
      },
      immediate: true,
    },
    isRefreshed: {
      async handler(val) {
        if (val) {
          await this.fetchAssetsList();
          this.isRefreshed = false;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchAssetsList();
  },
  computed: {
    ...mapGetters(["isLoadingUsers"]),
    totalUsers() {
      return this.getUsers.length;
    },
  },
  methods: {
    ...mapActions(["fetchAssets", "deleteAsset"]),
    async fetchAssetsList() {
      await this.fetchAssets(`page=${this.page}&pageSize=${this.pageSize}`);
      this.assets = this.$store.state.assets.assets.assets;
      this.total = this.$store.state.assets.assets.total;
    },
    viewAsset(asset) {
      this.selectedAsset = asset;
      this.showViewModal = true;
    },
    editAsset(asset) {
      this.isEdit = true;
      this.selectedAsset = asset;
      this.showEditModal = true;
    },
    async handleSaveChanges(editedUser) {
      await this.updateUser(editedUser);
      this.selectedUser = null;
      this.showEditModal = false;
    },
    confirmDeleteAsset(asset, index) {
      this.selectedAsset = asset;
      this.showDeleteAssetModal = true;
      this.deleteIndex = index;
    },
    async deleteSelectedAsset(asset) {
      try {
        const response = await this.deleteAsset(asset?.id);
        if (response?.status === 200) {
          this.assets.splice(this.deleteIndex, 1);
          this.total -= 1;
          this.showDeleteAssetModal = false;
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    },
  },
};
</script>
  
  <style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}
.text-end.edit i {
  cursor: pointer;
}
@media (max-width: 576px) {
  table {
    width: max-content;
  }
}
</style>