<template>
  <div class="card-body">
    <div class="chart-container-2">
      <canvas id="chart2"></canvas>
    </div>
  </div>
</template>
  
  <script>
import { Chart, ArcElement, Tooltip, Legend, Title, PieController } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ArcElement, Tooltip, Legend, Title, PieController, ChartDataLabels);

export default {
  name: "PieChart",
  props: {
    pieChart: {
      required: true,
    },
    chartTitle: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const ctx = document.getElementById("chart2").getContext("2d");
      new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.pieChart?.labels,
          datasets: this.pieChart?.datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: this.chartTitle,
              font: {
                size: 18
              }
            },
            datalabels: {
              display: true,
              // eslint-disable-next-line
              formatter: (value, context) => {
                return value;
              },
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        },
      });
    },
  },
};
</script>
  
<style scoped>
.chart-container-2 {
  position: relative;
  min-height: 400px;
}
</style>
  
  
  
  