import instance from "../../config";

const state = {
    assets: []
};

const mutations = {
    SET_ASSETS(state, assets) {
        state.assets = assets
    }
};

const getters = {
    getAssets: (state) => state.assets
};

const actions = {
    // eslint-disable-next-line
    async createAssets({ commit }, payload) {
        try {
            return await instance.post("/assets", payload);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchAssets({ commit }, payload) {
        try {
            await instance.get(`/assets?${payload}`)
            .then(response => {
                commit('SET_ASSETS', response.data.data)
            })
        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async deleteAsset({ commit }, payload) {
        try {
            return await instance.delete(`/assets/${payload}`)
        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async updateAssets({ commit }, payload) {
        try {
            return await instance.put(`/assets/${payload?.id}`, payload.data);
        } catch (error) {
            console.error(error);
        }
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
