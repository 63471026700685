<template>
  <div class="container-sm">
    <BreadCrumb
      main="Users"
      sub="List"
    >
      <button type="button" @click="showEditModal = true; isEdit = false" class="btn-md btn btn-primary">
        <i class="lni lni-circle-plus"></i> Create User</button>
    </BreadCrumb>

    <div v-if="isLoadingUsers" class="loader-container">
      <LoaderComponent />
    </div>
    <div v-else class="row">
      <div class="card">
        <div class="card-body overflow-auto">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th class="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, userIdx) in users" :key="userIdx">
                <td>{{ user?.firstname }}</td>
                <td>{{ user?.lastname }}</td>
                <td>{{ user?.email }}</td>
                <td>{{ user?.role }}</td>
                <td class="text-end edit" >
                  <i class="lni lni-pencil fs-5 me-3 text-primary" @click="editUser(user)"></i>
                  <i class="lni lni-trash fs-5 text-danger" @click="confirmDeleteUser(user, userIdx)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <EditCreateUserModal
        v-if="showEditModal"
        :isEdit="isEdit"
        :selectedUser="selectedUser"
        @refresh="isRefreshed = true; selectedUser = null;"
        @closeHandler="showEditModal = false; selectedUser = null; isEdit = false"
      />

      <DeleteUserModal
        v-show="showDeleteUserModal"
        :selectedUser="selectedUser"
        @closeHandler="showDeleteUserModal = false"
        @delete="deleteSelectedUser(selectedUser)"
      />

      <div class="d-flex justify-content-between">
        <span class="align-self-center"
          >{{ displayedResponses }} out of {{ total }}</span
        >
        <PaginationComponent
          v-if="total > 0"
          :page="page"
          :total="total"
          :pageSize="pageSize"
          @changePage="changePage"
          class="align-self-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import paginationMixin from "@/mixins/paginationMixin";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import EditCreateUserModal from "./EditCreateUserModal.vue";
import DeleteUserModal from "./DeleteUserModal.vue";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";

export default {
  name: "UsersTableView",
  mixins: [paginationMixin],
  components: {
    BreadCrumb,
    EditCreateUserModal,
    LoaderComponent,
    PaginationComponent,
    DeleteUserModal
  },
  data() {
    return {
      users: [],
      page: 1,
      pageSize: 12,
      total: 0,
      selectedUser: null,
      showEditModal: false,
      showCreateUser: false,
      showDeleteUserModal: false,
      deleteIndex: null,
      isEdit: false,
      isRefreshed: false
    };
  },
  watch: {
    page: {
      async handler(val) {
        if (val) {
          await this.fetchUserList();
        }
      },
      immediate: true
    },
    isRefreshed: {
      async handler(val) {
        if (val) {
          await this.fetchUserList();
          this.isRefreshed = false;
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.fetchUserList();
  },
  computed: {
    ...mapGetters(["isLoadingUsers", "getUsers"]),
    totalUsers() {
      return this.getUsers.length;
    },
  },
  methods: {
    ...mapActions(["fetchUsers", "deleteUser", "updateUser"]),
    editUser(user) {
      this.isEdit = true;
      this.selectedUser = user;
      this.showEditModal = true;
    },
    async handleSaveChanges(editedUser) {
      await this.updateUser(editedUser)
      this.selectedUser = null;
      this.showEditModal = false;
    },
    async fetchUserList() {
      this.$store.commit("SET_LOADING", true);
      await this.fetchUsers({ page: this.page, pageSize: this.pageSize });
      this.users = this.getUsers?.users;
      this.total = this.getUsers?.total;
    },
    confirmDeleteUser(user) {
      this.selectedUser = user;
      this.showDeleteUserModal = true;
    },
    async deleteSelectedUser(user) {
      try {
        const response = await this.deleteUser(user?.id);
        if (response?.status === 200) {
          this.users.splice(this.deleteIndex, 1);
          this.total -= 1;
          this.showDeleteUserModal = false;
        }
      }
      // eslint-disable-next-line no-empty
      catch (error) {}
    }
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 40vh;
}
.text-end.edit i {
  cursor: pointer;
}
@media (max-width: 576px) {
  table {
    width: max-content;
  }
}
</style>

