export default {
    computed: {
        displayedResponses() {
            if (!this.total) return 0;
            const start = (this.page - 1) * this.pageSize + 1;
            const end = Math.min(this.page * this.pageSize, this.total);
            return `${start}-${end}`;
        }
    },
    methods: {
        changePage(page) {
            this.page = page;
        },
    }
}
