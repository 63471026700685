import instance from "../../config";

const state = {
  surveys: [],
  currentSurvey: null,
  surveyLocation: null,
  loading: false,
  isSubmitting: false,
  answeredSurveys: [],
  singleAnsweredSurvey: null,
  dashboardStats: null,
  currentAnswerResponse: null,
  reports: null
};

const mutations = {
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },
  SET_CURRENT_SURVEY(state, survey) {
    state.currentSurvey = survey;
  },
  SET_SURVEY_LOCATION(state, location) {
    state.surveyLocation = location;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_SUBMIT(state, isAswering) {
    state.isSubmitting = isAswering;
  },
  SET_ANSWERED_SURVEYS(state, surveys) {
    state.answeredSurveys = surveys;
  },
  VIEW_SINGLE_ANSWERED_SURVEYS(state, survey) {
    state.singleAnsweredSurvey = survey;
  },
  SET_DASHBOARD_STATS(state, stats) {
    state.dashboardStats = stats;
  },
  SET_CURRENT_ANSWER_RESPONSE(state, response) {
    state.currentAnswerResponse = response;
  },
  SET_REPORTS(state, response) {
    state.reports = response
  }
};

const getters = {
  getSurveys: (state) => state.surveys,
  getCurrentSurvey: (state) => state.currentSurvey,
  getSurveyLocation: (state) => state.surveyLocation,
  isLoading: (state) => state.loading,
  isSubmiting: (state) => state.isSubmitting,
  getListOfAnswerdSurveys: (state) => state.answeredSurveys,
  getSingleAnsweredSurvey: (state) => state.singleAnsweredSurvey,
  getDashboardStats: (state) => state.dashboardStats,
  getCurrentAnswerResponse: (state) => state.currentAnswerResponse,
  getReports: (state) => state.reports
};

const actions = {
  async fetchCurrentSurvey({ commit }, id) {
    commit("SET_LOADING", true);
    await instance
      .get(`/surveys/${id}`)
      .then((response) => {
        commit("SET_CURRENT_SURVEY", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  async fetchSurveys({ commit }) {
    commit("SET_LOADING", true);
    await instance
      .get("/surveys")
      .then((response) => {
        commit("SET_SURVEYS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  async submitAnswers({ commit }, answers) {
    commit("SET_SUBMIT", true);
    try {
      return await instance.post("/answers", answers);
    } catch (error) {
      console.error(error);
      commit("SET_SUBMIT", false);
    }
  },
  async fetchSingleAnsweredSurvey({ commit }, payload) {
    if (!payload?.id) {
      return;
    }
    commit("SET_LOADING", true);
    await instance
      .get(`/answers/${payload?.id}${payload?.query}`)
      .then((response) => {
        commit("VIEW_SINGLE_ANSWERED_SURVEYS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  async fetchAnsweredSurveys({ commit }, payload) {
    commit("SET_LOADING", true);
    await instance
      .get(`/surveys/admin?page=${payload.page}&pageSize=${payload.pageSize}`)
      .then((response) => {
        commit("SET_ANSWERED_SURVEYS", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  // eslint-disable-next-line
  async fetchDashboardStats({ commit }) {
    await instance
      .get("/surveys/dashboard")
      .then((response) => {
        commit("SET_DASHBOARD_STATS", response.data.data);
      }).catch((error) => {
        console.error(error);
      });
  },
  async fetchCurrentAnswerResponse({ commit }, id) {
    commit("SET_LOADING", true);
    await instance
      .get(`answers/single/${id}`)
      .then((response) => {
        commit("SET_CURRENT_ANSWER_RESPONSE", response.data.data);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADING", false);
      });
  },
  async fetchReports({ commit }, payload) {
    await instance
      .get(`/surveys/reports?${payload}`)
      .then((response) => {
        commit("SET_REPORTS", response.data.data)
      })
  },
  // eslint-disable-next-line
  async downloadCSVHandler ({ commit }, payload) {
    try {
      const response = await instance
        .get(`/surveys/download-csv?${payload}`, {
          responseType: 'blob'
        })

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);

        link.click();
        link.remove();
    } catch (error) {
      console.error('Error downloading the CSV:', error);
    }
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
};
