<template>
  <div style="width: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "DashboardComponent",
};
</script>
