<template>
    <div class="container-sm">
        <BreadCrumb main="Reports" sub="Stats">
            <button
                v-if="category === 'csv' && reports"
                class="btn btn-primary"
                @click="downloadHandler"
            >
                Download CSV
            </button>
        </BreadCrumb>

        <div class="row">
            <div class="col-3 ps-0">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <label for="select" class="form-label">Filter By</label>
                            <select v-model="category" class="form-select form-select-md">
                                <option selected disabled :value="null">Choose...</option>
                                <option value="csv">CSV / Excel</option>
                                <option value="chart">Charts</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="multiple-select-field" class="form-label">Region</label>
                            <select class="form-select" multiple v-model="selectedRegions">
                                <option value="Central Region">Central</option>
                                <option value="Western Region">Western</option>
                                <option value="Eastern Region">Eastern</option>
                                <option value="Northern Region">Northern</option>
                            </select>
                        </div>

                        <div class="mb-3" v-if="category === 'chart'">
                            <label for="select" class="form-label">Charts</label>
                            <select v-model="selectedChart" class="form-select form-select-md">
                                <option :value="null" selected disabled>Choose...</option>
                                <option value="pie">Pie Chart</option>
                                <option value="bar">Bar Graph</option>
                            </select>
                        </div>

                        <div class="mb-3" v-if="(category === 'csv') || (category === 'chart' && selectedChart !== 'bar')">
                            <label for="select" class="form-label">Surveys</label>
                            <select class="form-select" v-model="selectedSurvey">
                                <option :value="null" selected disabled>Choose...</option>
                                <option
                                    v-for="(survey, index) in surveys"
                                    :key="index"
                                    class="py-1"
                                    :value="survey.id"
                                >
                                    {{ survey.title }}
                                </option>
                            </select>
                        </div>

                        <div
                            class="mb-3"
                            v-if="
                                category === 'chart' &&
                                selectedSurvey &&
                                filteredCurrentAllQuestions.length &&
                                selectedChart === 'pie'
                            "
                        >
                            <label for="select" class="form-label">Fields</label>
                            <select class="form-select" v-model="selectedField">
                                <option :value="null" selected disabled>Choose...</option>
                                <option
                                    v-for="question in filteredCurrentAllQuestions"
                                    :value="question"
                                    :key="question.id"
                                >
                                    {{ question?.text }}
                                </option>
                            </select>
                        </div>

                        <button
                            :disabled="isDisabledButton"
                            @click.prevent="submitHandler"
                            class="btn btn-sm btn-primary w-100"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-9 pe-0">
                <div v-if="!reports" class="d-flex justify-content-center align-items-center h-75">
                    No data available
                </div>
                <div v-else class="card">
                    <div class="card-body overflow-scroll">
                        <table
                            class="table table-bordered table-striped"
                            v-if="reports && category === 'csv'"
                        >
                            <thead>
                                <tr>
                                    <th class="frozen-column">Agent Name</th>
                                    <th v-for="(question, id) in filteredAllQuestions" :key="id">
                                       {{ question?.text }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(response, idx) in reports?.responses" :key="idx">
                                    <td style="min-width: 180px;" class="frozen-column">
                                        {{ response?.user.firstname }} {{ response?.user.lastname }}
                                    </td>
                                    <td v-for="(question, i) in filteredAllQuestions" :key="i">
                                        <span v-if="getAnswerByQuestion(question, response?.Answer)">
                                            {{ getAnswerByQuestion(question, response?.Answer) }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div>
                            <PieChart
                                v-if="category === 'chart' && reports && selectedChart === 'pie'"
                                :pieChart="JSON.parse(reports)"
                                :chartTitle="selectedField.text"
                            />
                        </div>

                        <div>
                            <Chart
                                v-if="category === 'chart' && reports && selectedChart === 'bar'"
                                :graphData="JSON.parse(reports)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from './Chart.vue';
import { mapActions } from 'vuex';
import PieChart from './PieChart.vue'
import BreadCrumb from "@/components/common/BreadCrumb.vue";

export default {
    name: "ReportsComponent",
    components: {
        Chart,
        PieChart,
        BreadCrumb,
    },
    data() {
        return {
            category: null,
            selectedChart: null,
            selectedRegions: [],
            selectedSurvey: null,
            selectedField: null,
            surveys: [],
            reports: null,
            piechart: {}
        }
    },
    watch: {
        category: {
            handler(val) {
                if (val !== null) {
                    this.selectedRegions = []
                    this.selectedSurvey = null
                    this.reports = null
                }
            }
        },
        selectedChart: {
            handler(val) {
                if (val !== null) {
                    this.selectedRegions = []
                    this.selectedSurvey = null
                    this.reports = null
                }
            }
        },
        selectedSurvey: {
            async handler(val) {
                if (this.category === 'chart' && this.selectedChart === 'pie') {
                    await this.fetchCurrentSurvey(val);
                }
            }
        }
    },
    computed: {
        currentAllQuestions() {
            return this.$store?.state?.survey?.currentSurvey?.sections?.flatMap(section => section.questions) || []
        },
        filteredCurrentAllQuestions() {
            return this.currentAllQuestions.filter(question => question?.type === 'radio' || question?.type === 'checkbox');
        },
        allQuestions () {
            return this.reports?.survey?.sections?.flatMap(section => section.questions) || [];
        },
        filteredAllQuestions() {
            return this.allQuestions.filter(question => question?.type !== 'table');
        },
        isDisabledButton() {
            if (this.category === 'csv' && this.selectedSurvey) {
                return false
            }
            else if (this.category === 'chart' && this.selectedChart === 'pie' && this.selectedField) {
                return false
            }
            else if (this.category === 'chart' && this.selectedChart === 'bar') {
                return false
            }
            return true
        }
    },
    async mounted() {
        await this.fetchSurveys();
        this.surveys = this.$store.state.survey.surveys;
    },
    methods: {
        ...mapActions(["fetchSurveys", "fetchReports", "fetchCurrentSurvey", "downloadCSVHandler"]),
        async submitHandler() {
            this.reports = null;
            let query = `category=${this.category}&surveyId=${this.selectedSurvey}&region=${this.selectedRegions.join(',')}`

            if (this.selectedChart) {
                query = `${query}&chart=${this.selectedChart}`
            }

            if (this.selectedField?.id) {
                query = `${query}&field=${this.selectedField.id}`
            }

            await this.fetchReports(query)
            this.reports = this.$store.state.survey.reports
        },
        getAnswerByQuestion(question, answers) {
            const answer = (answers.find(answer => answer.questionId === question.id))
            return  answer?.answerText || answer?.options.join(', ') || null
        },
        async downloadHandler() {
            const query = `surveyId=${this.selectedSurvey}&region=${this.selectedRegions.join(',')}`
            await this.downloadCSVHandler(query);
        }
    },
}
</script>

<style scoped>
thead th {
    width: 300px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
}

th.frozen-column, td.frozen-column {
    position: sticky;
    left: -1rem;
    z-index: 1;
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.8);
}

button:disabled {
    cursor: not-allowed;
}

@media (max-width: 576px) {
    .col-3, .col-9 {
        width: 100%;
    }
}
</style>
