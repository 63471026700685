<template>
  <div class="card-body">
    <div class="d-block align-items-center ms-auto font-13 gap-2 mb-3">
    </div>
    <div class="chart-container-1">
      <canvas id="chart1"></canvas>
    </div>
  </div>
</template>
  
<script>
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  BarController,
} from "chart.js";

Chart.register(BarElement, CategoryScale, LinearScale, BarController);

export default {
  name: "BarChart",
  props: {
    graphData: {
      required: true,
    },
  },
  async mounted() {
    await this.renderChart();
  },
  methods: {
    renderChart() {
      const ctx = document.getElementById("chart1").getContext("2d");

      if (this.graphData?.datasets.length) {
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: this.graphData?.labels || [''],
            datasets: this.graphData?.datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return `${context?.dataset?.label} : ${context?.raw}`
                  }
                }
              }
            },
            scales: {
              x: {
                display: this.graphData?.labels ? true : false,
              },
            }
          },
        });
      }
    },
  },
};
</script>

  <style scoped>
.chart-container-1 {
  position: relative;
  height: 400px;
}
</style>
  
  