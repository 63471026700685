<template>
  <ModalComponent
    :title="`${!isEdit ? 'Create' : 'Edit'} Assets`"
    @close="closeHandler"
  >
    <template v-slot:body>
      <section class="register">
        <div class="register-stepper">
          <div
            class="step"
            :class="{ 'step-active': step === 1, 'step-done': step > 1 }"
          >
            <span class="step-number">1</span>
          </div>
          <div
            class="step"
            :class="{ 'step-active': step === 2, 'step-done': step > 2 }"
          >
            <span class="step-number">2</span>
          </div>
          <div
            class="step"
            :class="{ 'step-active': step === 3, 'step-done': step > 3 }"
          >
            <span class="step-number">3</span>
          </div>
          <div
            class="step"
            :class="{ 'step-active': step === 4, 'step-done': step > 4 }"
          >
            <span class="step-number">4</span>
          </div>
        </div>

        <!-- Form 1 -->
        <transition name="slide-fade">
          <section v-show="step === 1">
            <form class="form" method="post" action="#" @submit.prevent="next">
              <div class="label-info">
                <div>
                  <label for="assetName">Asset Name</label>
                  <input
                    type="text"
                    name="name"
                    :value="selectedAsset?.name"
                    placeholder="Asset Name"
                    class="form-control"
                    required
                  />
                </div>
                <div>
                  <label for="category" class="mt-3">Category</label>
                  <select name="category" class="form-control" required>
                    <option disabled selected :value="null">Please select one</option>
                    <option :selected="selectedAsset?.category === 'Machinery'" value="Machinery"> Machinery</option>
                    <option :selected="selectedAsset?.category === 'Vehicles'" value="Vehicles">Vehicles</option>
                    <option :selected="selectedAsset?.category === 'IT Equipment'" value="IT Equipment">IT Equipment</option>
                    <option :selected="selectedAsset?.category === 'Real Estate'" value="Real Estate">Real Estate</option>
                    <option :selected="selectedAsset?.category === 'Other'" value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="label-info">
                <label for="location" class="mt-3">Location</label>
                <input
                  type="text"
                  name="location"
                  :value="selectedAsset?.location"
                  autocomplete="customer.location"
                  placeholder="Location"
                  class="form-control"
                  required
                />
              </div>
              <div
                class="cta"
                data-style="see-through"
                data-alignment="right"
                data-text-color="custom"
              >
                <p class="cta-color">
                  <span class="link_wrap">
                    <button class="form-control btn btn-primary mt-3" type="submit" value="one">Next</button>
                  </span>
                </p>
              </div>
            </form>
          </section>
        </transition>

        <!-- Form 2 -->
        <transition name="slide-fade">
          <section v-show="step === 2">
            <form class="form" method="post" action="#" @submit.prevent="next">
              <div class="label-info">
                <div>
                  <label for="purchaseDate" class="mt-3">Purchase Date</label>
                  <input
                    type="date"
                    name="purchaseDate"
                    :value="formatDate(selectedAsset?.purchaseDate)"
                    autocomplete="customer.purchaseDate"
                    placeholder="Purchase Date"
                    class="form-control"
                  />
                </div>
                <div>
                  <label for="purchasePrice" class="mt-3">Purchase Price</label>
                  <input
                    type="number"
                    name="purchasePrice"
                    :value="selectedAsset?.purchasePrice"
                    autocomplete="customer.purchasePrice"
                    placeholder="Purchase Price"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="label-info">
                <div>
                  <label for="currentValue" class="mt-3">Current Asset Value</label>
                  <input
                    type="number"
                    name="currentAssetValue"
                    :value="selectedAsset?.currentAssetValue"
                    autocomplete="customer.currentValue"
                    placeholder="Current Asset Value"
                    class="form-control"
                  />
                </div>
                <div>
                  <label for="depreciationRate" class="mt-3">Depreciation Rate (%)</label>
                  <input
                    type="number"
                    name="depreciationRate"
                    :value="selectedAsset?.depreciationRate"
                    autocomplete="customer.depreciationRate"
                    placeholder="Depreciation Rate (%)"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="label-info">
                <div>
                  <label for="condition" class="mt-3">Condition</label>
                  <select name="condition" class="form-control">
                    <option disabled selected :value="null">Please select one</option>
                    <option :selected="selectedAsset?.condition === 'new'" value="new">New</option>
                    <option :selected="selectedAsset?.condition === 'good'" value="good">Good</option>
                    <option :selected="selectedAsset?.condition === 'fair'" value="fair">Fair</option>
                    <option :selected="selectedAsset?.condition === 'poor'" value="poor">Poor</option>
                  </select>
                </div>
                <div>
                  <label for="serialNumber" class="mt-3">Serial Number</label>
                  <input
                    type="text"
                    name="serialNumber"
                    :value="selectedAsset?.serialNumber"
                    autocomplete="customer.serialNumber"
                    placeholder="Serial Number"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="label-info">
                <div>
                  <label for="manufacturer" class="mt-3">Manufacturer</label>
                  <input
                    type="text"
                    name="manufacturer"
                    :value="selectedAsset?.manufacturer"
                    autocomplete="customer.manufacturer"
                    placeholder="Manufacturer"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group cta-step mt-3">
                <div class="cta prev">
                  <p class="cta-color">
                    <span class="link_wrap">
                      <button @click.prevent="prev()" class="btn btn-secondary">Previous</button>
                    </span>
                  </p>
                </div>
                <div class="cta">
                  <p class="cta-color">
                    <span class="text"></span>
                    <span class="link_wrap">
                      <button type="submit" class="btn btn-primary">Next</button>
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </section>
        </transition>
        <!-- Start 3 -->
        <transition name="slide-fade">
          <section v-show="step === 3">
            <form class="form" method="post" action="#" @submit.prevent="next">
              <div class="label-info">
                <div>
                  <label for="warrantExpiryDate" class="mt-3">Warrant Expiry Date</label>
                  <input
                    type="date"
                    name="expiryDate"
                    :value="formatDate(selectedAsset?.expiryDate)"
                    autocomplete="customer.warrantExpiryDate"
                    placeholder="Warrant Expiry Date"
                    class="form-control"
                  />
                </div>
                <div>
                  <label for="maintenanceShedule" class="mt-3">Maintenance Shedule</label>
                  <input
                    type="text"
                    name="maintenanceShedule"
                    :value="selectedAsset?.maintenanceShedule"
                    autocomplete="customer.maintenanceShedule"
                    placeholder="Maintenance Shedule"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="label-info">
                <div>
                  <label for="lastMaintenanceDate" class="mt-3">Last Maintenance Date</label>
                  <input
                    type="date"
                    name="lastMaintenanceDate"
                    :value="formatDate(selectedAsset?.lastMaintenanceDate)"
                    autocomplete="customer.lastMaintenanceDate"
                    placeholder="Last Maintenance Date"
                    class="form-control"
                  />
                </div>
                <div>
                  <label for="nextMaintenanceDate" class="mt-3">Next Maintenance Date</label>
                  <input
                    type="date"
                    name="nextMaintenanceDate"
                    :value="formatDate(selectedAsset?.nextMaintenanceDate)"
                    autocomplete="customer.nextMaintenanceDate"
                    placeholder="Next Maintenance Date"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group cta-step mt-3">
                <div class="cta prev">
                  <p class="cta-color">
                    <span class="link_wrap">
                      <button class="btn btn-secondary" @click.prevent="prev()">Previous</button>
                    </span>
                  </p>
                </div>
                <div class="cta">
                  <p class="cta-color">
                    <span class="text"></span>
                    <span class="link_wrap">
                      <button type="submit" class="btn btn-primary">Next</button>
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </section>
        </transition>
        <!-- End 3 -->
        <!-- Start 4 -->
        <transition name="slide-fade">
          <section v-show="step === 4">
            <form class="form" action="#" @submit.prevent="customerRegister">
              <div class="label-info">
                <div>
                  <label for="assignedTo" class="mt-3">Assigned To</label>
                  <select name="userId" class="form-control" required>
                    <option disabled selected :value="null">Please select one</option>
                    <option
                      v-for="(user, index) in users"
                      :key="index" :value="user?.id"
                      :selected="selectedAsset?.user.id === user?.id"
                    >
                      {{ user?.firstname }} {{ user?.lastname }} ({{ user?.role }})
                    </option>
                  </select>
                </div>
                <div>
                  <label for="usageStatus" class="mt-3">Usage Status {{ selectedAsset?.usageStatus }}</label>
                  <select name="usageStatus" class="form-control">
                    <option disabled selected :value="null">Please select one</option>
                    <option :selected="selectedAsset?.usageStatus === 'In Use'" value="In Use">In Use</option>
                    <option :selected="selectedAsset?.usageStatus === 'In Storage'" value="In Storage">In Storage</option>
                    <option :selected="selectedAsset?.usageStatus === 'Under Maintenance'" value="Under Maintenance">Under Maintenance</option>
                    <option :selected="selectedAsset?.usageStatus === 'Retired'" value="Retired">Retired</option>
                  </select>
                </div>
                <div>
                  <label for="utilisationRate" class="mt-3">Utilisation Rate</label>
                  <input
                    type="number"
                    name="utilisationRate"
                    :value="selectedAsset?.utilisationRate"
                    autocomplete="customer.utilisationRate"
                    placeholder="Utilisation Rate"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group cta-step mt-3">
                <div class="cta prev">
                  <p class="cta-color">
                    <span class="link_wrap">
                      <button @click.prevent="prev()" class="btn btn-secondary">Previous</button>
                    </span>
                  </p>
                </div>

                <div
                  class="cta"
                  data-style="see-through"
                  data-alignment="right"
                  data-text-color="custom"
                >
                  <span class="link_wrap">
                    <button type="submit" class="btn btn-success">Submit</button>
                  </span>
                </div>
              </div>
            </form>
          </section>
        </transition>
        <!-- End 4 -->
      </section>
    </template>
  </ModalComponent>
</template>
<script>
import { mapActions } from "vuex";
import ModalComponent from "@/components/common/ModalComponent.vue";

export default {
  name: "EditCreateUserModal",
  components: {
    ModalComponent,
  },
  props: {
    isEdit: {
      default: false
    },
    selectedAsset: {
      required: false
    }
  },
  data: () => {
    return {
      steps: {},
      step: 1,
      formData: {},
      users: [],
    };
  },
  async mounted() {
    await this.fetchAllUsers();
    this.users = this.$store?.state?.users?.allUsers;
  },
  methods: {
    ...mapActions(["fetchAllUsers", "createAssets", "updateAssets"]),

    prev() {
      this.step--;
    },

    formatEventHandler (event) {
      for (let element of event.target.elements) {
        if (element?.name && element?.value !== "") {
          this.formData[element.name] = element.value;
        }
      }
    },

    next($event) {
      this.formatEventHandler($event);
      this.step++;
    },

    closeHandler() {
      this.$emit('closeHandler');
    },

    async customerRegister ($event) {
      this.formatEventHandler($event);
      if (this.isEdit) {
        await this.updateAssets({ id: this.selectedAsset?.id, data: this.formData })
          .then(response => {
            if (response?.status === 200) {
              this.$emit('closeHandler');
              this.$emit('refreshHandler')
            }
          })
      } else {
        await this.createAssets(this.formData).then(response => {
          if (response?.status === 201) {
            this.$emit('closeHandler');
            this.$emit('refreshHandler')
          }
        });
      }
    },

    formatDate(date) {
      return this.selectedAsset ? new Date(date).toISOString().split('T')[0] : '';
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/Variables.scss";

.register {
  display: block;
  max-width: 7000px;
  padding: 2rem;
  &-stepper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin: 0 auto 1.5em;

    &::before {
      z-index: 0;
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      top: calc(50% - 1px);
      background: $color-gray;
      width: calc(100% - 20px);
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border: 2px solid $color-gray;
      color: $color-gray;
      background-color: $color-white;
      border-radius: 50%;
      min-width: 25px;
      min-height: 25px;
      line-height: 20px;
      font-size: 16px;

      &-active {
        color: $brand-primary;
        background-color: $color-white;
        border-color: $brand-primary;
      }

      &-done {
        color: #a7e4b5;
        border-color: #a7e4b5;
      }

      &-number {
        font-family: $font-montserrat;
        font-weight: 800;
        line-height: 1;
        vertical-align: middle;
      }
    }
  }

  .form {
    &-group {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: baseline;

      label {
        text-align: left;
        font-size: 1.1rem;
        line-height: 1.1;
        padding-bottom: 0.5rem;
      }

      &.cta-step {
        color: $color-white;
        justify-content: space-between;
      }
    }

    .cta-color,
    .cta-color input,
    .cta-color .link_text {
      color: $color-white;
      font-family: $font-montserrat;
      font-size: 1.1rem;
      text-decoration: none;
    }

    .cta-color .link_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .arrow-prev {
        position: relative;
        display: inline-block;
        transform: translate(0);
        transition: transform 0.3s ease-in-out;

        &::before {
          content: "<";
          position: absolute;
          top: -17px;
          left: -25px;
        }
      }

      .arrow-next {
        position: relative;
        display: inline-block;
        transform: translate(0);
        transition: transform 0.3s ease-in-out;

        &::before {
          content: ">";
          position: absolute;
          top: -10px;
          left: -25px;
        }
      }

      &:hover .arrow-prev {
        transform: translate(-5px);
      }

      &:hover .arrow-next {
        transform: translate(5px);
      }
    }
  }
  input[type="submit"],
  input[type="text"],
  input[type="select"],
  input[type="date"] {
    -webkit-appearance: none;
    border-radius: 5px;
    width: 100%;
  }

  input[type="submit"] {
    cursor: pointer;
    position: relative;
    padding-right: 36px;
    background: none;
    width: fit-content;
  }
}
.label-info {
  display: grid;
}
</style>

