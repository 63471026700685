<template>
    <div id="stepper1" class="container-sm bs-stepper">
        <div class="bs-stepper-content">
            <form @submit.prevent="">
                <div id="test-l-1" role="tabpanel" :class="['active bs-stepper-pane']"
                    aria-labelledby="stepper1trigger1">
                    <h5 class="mb-3">{{ section.title }}</h5>

                    <div class="row g-3">
                        <SurveyQuestion v-for="(question, questionId) in section?.questions" :key="questionId"
                            :question="question" :questionId="questionId" :errors="errors"
                            :currentQuestionAnswer="getQuestionAnswer(question?.id)" @updateAnswer="updateAnswer"
                            @validateField="validateField" @updateCheckboxAnswer="updateCheckboxAnswer"
                            @updateTableAnswer="updateTableAnswer" />

                        <div class="col-12 px-0">
                            <div class="d-flex align-items-center gap-3">
                                <button v-if="sectionIdx > 0" class="btn btn-outline-secondary px-4" type="button"
                                    @click="prevButtonHandler()">
                                    Prev
                                </button>
                                <button :class="[isLastSection(sectionIdx, survey) ? 'btn-success' : 'btn-primary', 'btn px-4',]" 
                                    @click="isLastSection(sectionIdx, survey) ? submitHandler() : nextButtonHandler()"
                                    type="submit"
                                >
                                    <spinner-component
                                        v-if="isLastSection(sectionIdx, survey) && isSubmiting"></spinner-component>
                                    <span v-else-if="isLastSection(sectionIdx, survey) && !isSubmiting">submit</span>
                                    <span v-else>Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import SurveyQuestion from "./SurveyQuestion.vue";
import SpinnerComponent from "../common/SpinnerComponent.vue";

export default {
    name: 'SurveySection',
    components: {
        SurveyQuestion,
        SpinnerComponent
    },
    props: {
        section: {
            type: Object,
            required: true
        },
        sectionIdx: {
            type: Number,
            required: true
        },
        errors: {
            type: Array,
            required: true
        },
        survey: {
            type: Object,
            required: true
        },
        currentSelectedSurvey: {
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        isSubmiting: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        updateAnswer(questionId, answer) {
            this.$emit('updateAnswer', questionId, answer)
        },
        validateField(questionId, validate) {
            this.$emit('validateField', questionId, validate)
        },
        updateCheckboxAnswer(questionId, answer, isChecked) {
            this.$emit('updateCheckboxAnswer', questionId, answer, isChecked)
        },
        updateTableAnswer(questionId, answer) {
            this.$emit('updateTableAnswer', questionId, answer)
        },
        prevButtonHandler() {
            this.$emit('prevButtonHandler')
        },
        nextButtonHandler() {
            this.$emit('nextButtonHandler')
        },
        submitHandler() {
            this.$emit('submitHandler')
        },
        isLastSection(sectionIdx, survey) {
            return sectionIdx === (survey?.sections.length - 1)
        },
        getQuestionAnswer(questionId) {
            const answer = this.currentSelectedSurvey?.answers.filter((answer) => answer.questionId === questionId)
            return Array.isArray(answer) ? answer[0] : null
        }
    }
};
</script>