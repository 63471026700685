<template>
    <div class="card mb-0">
        <div class="card-body">
             
            <div class="col-12 col-lg-12">
                <label class="form-label">{{ question?.text }}</label>
                <ValidationProvider
                    v-if="['text', 'email', 'number', 'url'].includes(question?.type)"
                    name="question.text"
                    v-slot="{ validate }"
                >
                    <input
                        class="px-0 form-control"
                        v-model="selectedAnswer"
                        :type="question?.type"
                        :placeholder="`Enter ${question?.text}`"
                        :name="question?.id"
                        :required="question?.required ? true : false"
                        @input="
                            updateAnswer(question.id, $event.target.value);
                            validateField(question.id, validate)
                        "
                    />

                    <span
                        v-show="typeof errors[questionId] === 'boolean'"
                        class="text-danger"
                    >
                        This question is required.
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    v-if="question?.type === 'textarea'"
                    name="question.text"
                    v-slot="{ validate }"
                >
                    <textarea
                        class="px-3 form-control"
                        :name="question?.id"
                        v-model="selectedAnswer"
                        @input="
                            updateAnswer(question.id, $event.target.value);
                            validateField(question.id, validate)
                        "
                    ></textarea>

                    <span
                        v-show="typeof errors[questionId] === 'boolean'"
                        class="text-danger"
                    >
                        This question is required.
                    </span>
                </ValidationProvider>

                <div v-if="question?.type === 'checkbox'">
                    <div class="form-check" v-for="(option, checkBoxId) in question?.options" :key="checkBoxId">
                        <ValidationProvider
                            :name="`question.options[${checkBoxId}]`"
                            v-slot="{ validate }"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="option"
                                :value="option"
                                :name="question?.id"
                                :checked="selectedAnswers.includes(option)"
                                @change="
                                    updateCheckboxAnswer(question.id, option, selectedAnswers.includes(option));
                                    validateField(question.id, validate)
                                "
                            />
                            <label class="form-check-label" :for="option">{{ option }}</label>
                            <input
                                v-show="selectedAnswers.includes(option) && checkSubStr(option)"
                               
                                type="text"
                                class="px-0 form-control"
                                :placeholder="`Enter ${option}`"
                                :name="question?.id"
                                @input="updateCheckboxAnswer(question.id, { other: $event.target.value, option })"
                            />
                        </ValidationProvider>
                    </div>
                    <span
                        v-show="typeof errors[questionId] === 'boolean'"
                        class="text-danger"
                    >
                        This question is required.
                    </span>
                </div>

                <div v-if="question?.type === 'radio'">
                    <div class="form-check form-check-primary" v-for="(option, radioIndex) in question?.options" :key="radioIndex">
                        <ValidationProvider
                            :name="`question.options`"
                            v-slot="{ validate, errors }"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                v-model="selectedAnswer"
                                :name="question?.id"
                                :value="selectedAnswer === option ? option : null"
                                @change="updateAnswer(question.id, option); validateField(question.id, validate)"
                            />

                            <label class="form-check-label">{{ option }}</label>
                            <ValidationProvider
                                v-show="selectedAnswer === option && checkSubStr(option)"
                                :name="`question.options.other`"
                                v-slot="{ validate }"
                            >
                                <input
                                    type="text"
                                    class="px-0 form-control"
                                    v-model="selectedOtherAnswer"
                                    :placeholder="`Enter ${option}`"
                                    :name="question?.id"
                                    @input="updateAnswer(question.id, { other: $event.target.value }); validateField(question.id, validate)" 
                                />
                            </ValidationProvider>
                            <span v-show="errors.length" class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <span
                        v-show="typeof errors[questionId] === 'boolean'"
                        class="text-danger"
                    >
                        This question is required.
                    </span>
                </div>

                <div v-if="question?.type === 'table'">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th v-for="(header, headerIndex) in question?.columns" :key="headerIndex">{{ header }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in question?.rows" :key="rowIndex">
                                <td>{{ row }}</td>
                                <td v-for="(header, headerIndex) in question?.columns.slice(1)" :key="headerIndex">
                                    <ValidationProvider
                                        :name="`question.options[${headerIndex}]`"
                                        v-slot="{ validate }"
                                    >
                                        <input
                                            class="form-control"
                                            type="text"
                                            placeholder="Enter value here"
                                            :name="`${question?.id}-${rowIndex}-${headerIndex}`"
                                            @input="
                                                updateTableAnswer(question.id, { row: rowIndex, col: headerIndex, value: $event.target.value });
                                                validateField(`${question?.id}-${rowIndex}-${headerIndex}`, validate)
                                            "
                                        />

                                        <span
                                            v-show="
                                                errors[questionId]?.length &&
                                                typeof errors[questionId][getInputCount(rowIndex, headerIndex)] === 'boolean'
                                            "
                                            class="text-danger"
                                        >
                                            This question is required.
                                        </span>
                                    </ValidationProvider>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
    name: 'SurveyQuestion',
    components: {
        ValidationProvider
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        questionId: {
            type: Number,
            required: true
        },
        errors: {
            type: Array,
            required: true
        },
        currentQuestionAnswer: {
            required: true
        }
    },
    data() {
        return {
            selectedTableAnswers: [],
            selectedOtherAnswer: null,
            selectedAnswer: this.currentQuestionAnswer?.answerText || this.question.answer || '',
            selectedAnswers: this.currentQuestionAnswer?.options || this?.question?.answer || [],
        }
    },
    created() {
        if (this.question?.type === 'radio') {
            this.question.options.map(option => {
                if (this.currentQuestionAnswer?.answerText.includes(option)) {
                    this.selectedAnswer = option;
                    this.selectedOtherAnswer = this.currentQuestionAnswer?.answerText.replace(option, '').trim();
                }
            })
        }
        else if (this.question?.type === 'checkbox') {
            this.selectedAnswers = this.question.options.filter(item => 
                (this.currentQuestionAnswer?.options.some(item2 => {
                    // if (this.checkSubStr(item) && (item2.length > item.length) && item2.includes(item)) {
                    //     this.selectedOtherAnswer = {
                    //         [item]: item2.replace(item, '').trim()
                    //     }
                    // }
                    return item2.includes(item)
                }))
            );
        }
    },
    methods: {
        formatTableAnswer(row, col) {
            const answer = this.currentQuestionAnswer?.options.find(ans => ans.row === row && ans.col === col);
            return answer?.value || ''
        },
        updateAnswer(questionId, answer) {
            if (answer?.other) {
                this.$emit('updateAnswer', questionId, `${this.selectedAnswer} ${answer.other}`)
                return
            }
            this.selectedAnswer = answer
            this.$emit('updateAnswer', questionId, answer)
        },
        updateTableAnswer(questionId, option) {
            this.$emit('updateTableAnswer', questionId, option)
        },
        updateCheckboxAnswer(questionId, answer, isChecked) {
            if (answer?.other) {
                this.$emit('updateCheckboxAnswer', questionId, `${answer.option} ${answer.other}`)
                return
            }

            if (this.selectedAnswers.includes(answer)) {
                this.selectedAnswers = this.selectedAnswers.filter(ans => ans !== answer);
            } else {
                answer?.other ?
                    this.selectedAnswers.push(`${answer.option} ${answer.other}`) :
                    this.selectedAnswers.push(answer);
            }

            this.$emit('updateCheckboxAnswer', questionId, answer, isChecked)
        },
        validateField(questionId, validate) {
            this.$emit('validateField', questionId, validate)
        },
        checkSubStr(str) {
            return str.toLowerCase().includes('specify') || str.toLowerCase().includes('suggestions');
        },
        getInputCount (rowIndex, headerIndex) {
            return parseInt(rowIndex * (this.question?.columns.length - 1) + headerIndex)
        }
    }
}
</script>

<style scoped>
input[type="text"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="number"]:focus,
input[type="text"]::before,
input[type="url"]::before,
input[type="number"]::before,
input[type="email"]::before {
    border: 0;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
}
textarea, textarea:focus, textarea::before {
    outline: none;
    box-shadow: none;
}
</style>
