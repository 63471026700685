<template>
  <div class="container-sm">
    <h6 class="mb-0 text-uppercase px-0">Survey List</h6>
    <hr />
    <div v-if="isLoading" class="loader-container">
      <LoaderComponent />
    </div>
    <div v-else class="col">
      <div class="row" v-for="(survey, surveyIdx) in surveys" :key="surveyIdx">
        <div class="card">
          <div class="card-body">
            <div>
              <h5 class="card-title">{{ survey.title }}</h5>
            </div>
            <p class="card-text">{{ survey.introduction }}</p>
           
            
              <router-link
                :to="`/survey/${survey.id}`"
                class="btn btn-primary btn-sm"
              >
                Take Survey
              </router-link>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoaderComponent from "@/components/common/LoaderComponent.vue";
export default {
  name: "SurveyTableView",
  components: {
    LoaderComponent
  },
  data() {
    return {
      surveys: [],
    };
  },
  methods: {
    ...mapActions(["fetchSurveys"]),
  },

  async mounted() {
    this.$store.commit("SET_LOADING", true);
    await this.fetchSurveys();
    this.surveys = this.$store.state.survey.surveys;
  },
  computed: {
        ...mapGetters(['isLoading']),
    },

};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

</style>
