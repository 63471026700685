<template>
    <ModalComponent title="Delete Asset" @close="closeHandler">
        <template v-slot:body>
            <p class="mb-0">
                Are you sure you want to delete
                <span class="text-primary">{{ selectedAsset?.name }} ({{ selectedAsset?.serialNumber }})</span>?
            </p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-sm btn-secondary"
                @click.prevent="closeHandler">Cancel</button>
            <button type="button" class="btn btn-sm btn-danger"
                @click.prevent="deleteSelectedAsset(selectedAsset)">Delete</button>
        </template>
    </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/common/ModalComponent.vue";

export default {
    name: "DeleteAssetModal",
    components: {
        ModalComponent,
    },
    props: {
        selectedAsset: {
            required: true,
        },
    },
    methods: {
        deleteSelectedAsset(asset) {
            this.$emit("delete", asset);
        },
        closeHandler() {
            this.$emit("closeHandler");
        },
    },
};
</script>
