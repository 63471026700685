import instance from '../../config'

const state = {
    isAuthenticated: false,
    authError: null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    isLogging: false
}

const mutations = {
    SET_AUTH(state, auth) {
        state.isAuthenticated = auth
    },
    SET_AUTH_ERROR(state, error) {
        state.authError = error
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_LOGGING(state, isLogging) {
        state.isLogging = isLogging
    }
}

const getters = {
    isAuthenticated: state => state.isAuthenticated,
    authError: state => state.authError,
    isLogging: state => state.isLogging
}

const actions = {
    async login({ commit }, credentials) {
        commit('SET_LOGGING', true)
        await instance.post('/login', credentials)
        .then(response => {
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            commit('SET_AUTH', true)
            commit('SET_USER', response.data.user)
            commit('SET_AUTH_ERROR', null)
            commit('SET_LOGGING', false)
        })
        .catch((error) => {
            commit('SET_AUTH_ERROR', error.response.data.error)
            commit( 'SET_LOGGING', false)
        })
    },
    async logout({ commit }) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        commit('SET_AUTH', false)
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}
