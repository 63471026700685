var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.isLoading)?_c('div',{staticClass:"loader-container"},[_c('LoaderComponent')],1):_c('div',[_c('BreadCrumb',{attrs:{"main":"Survey","sub":"Answers"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack(`/single-survey/${_vm.currentResponse?.surveyId}`)}}},[_vm._v("Go Back")])]),_vm._l((_vm.currentResponse?.Answer),function(answer,idx){return _c('div',{key:idx,staticClass:"row"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(answer?.question.text))]),(
              ['email', 'number', 'url', 'text'].includes(
                answer?.question.type
              )
            )?_c('div',[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":answer?.answerText}})]):_vm._e(),(answer?.question.type === 'checkbox')?_c('div',_vm._l((answer?.question.options),function(option,index){return _c('div',{key:index},[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","disabled":""},domProps:{"checked":answer?.options.includes(option)}}),_c('label',{staticClass:"form-check-label",attrs:{"for":option}},[_vm._v(_vm._s(option))])])])}),0):_vm._e(),(answer?.question.type === 'radio')?_c('div',_vm._l((answer?.question.options),function(option,index){return _c('div',{key:index},[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","disabled":""},domProps:{"checked":answer?.answerText.includes(option)}}),_c('label',{staticClass:"form-check-label",attrs:{"for":option}},[_vm._v(_vm._s(option))])])])}),0):_vm._e(),(answer?.question.type === 'textarea')?_c('div',[_c('textarea',{staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":answer?.answerText}})]):_vm._e(),(answer?.question.type === 'table')?_c('div',[_c('table',{staticClass:"table table-bordered"},[_c('thead',[(answer?.question.columns)?_c('tr',_vm._l((answer?.question.columns),function(column,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(column)+" ")])}),0):_vm._e()]),_c('tbody',_vm._l((answer?.question.rows),function(row,rowIdx){return _c('tr',{key:rowIdx},[_c('td',[_vm._v(_vm._s(row))]),_vm._l((answer?.question.columns.slice(
                      1
                    )),function(column,colIdx){return _c('td',{key:colIdx},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.formatTableValue(answer, colIdx, rowIdx)}})])})],2)}),0)])]):_vm._e()])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }